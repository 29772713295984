<template>
  <div>
    <OrganismsGsdBar v-if="abTests.originVariant" :vertical="_VERTICAL" />

    <molecules-hero-header
      :image-config="{
        img: `${APP_ASSET_BASEURL}edge/static/images/hero/health_hero_images_v2_1x.webp`,
        class: 'max-w-full w-[28rem] lg:h-[23.44rem]',
        dimensions: {
          width: 448,
          height: 380
        }
      }"
      :buttons="abTests?.originVariant ? [
        {
          theme: 'primary',
          text: 'COMPARE & SAVE',
          url: verticalData?.formPage
        }
      ]: [
        {
          theme: 'primary',
          text: 'CHAT WITH AN EXPERT',
          callback: intercomAction,
          class: 'w-full md:w-fit'
        },
        {
          theme: 'secondary',
          text: 'GET QUOTES ONLINE',
          url: verticalData?.formPage,
          class: 'w-full md:w-fit'
        }
      ]
      "
      title-tag="p"
      img-alt="Want better value health insurance? Join The Club"
    >
      <template v-if="abTests.originVariant" #header>
        Want better value
        <br />
        health insurance?
        <br />
        <span class="font-extrabold">Join The Club.</span>
      </template>

      <template v-else #header>
        How Do You
        <br />
        Want To Compare
        <br />
        <span class="font-extrabold">Health Insurance?</span>
      </template>

      <template v-if="abTests.originVariant" #sub-header>
        We have
        <img
          class="inline w-24"
          :src="expertEaseImg"
          width="96"
          height="16.69"
          alt="Expert ease"
        />

        in comparing health insurance.<br />
        Our experts have saved people an average of {{ HOME_TALK_TO_US.averageSave }} on their cover.
        Plus they even take care of the paperwork.
      </template>

      <template v-else #sub-header>
        Compare by yourself or <span class="font-extrabold">use our experts</span>
        <br />
        to help find a perfect policy from our panel.
      </template>
    </molecules-hero-header>

    <MoleculesTrustpilot
      :layout="!devices.isDesktop.value ? 'compact' : 'default'"
      class="py-8 border border-solid border-gray-350 min-h-[5.875rem] bg-white"
    />

    <molecules-direct-price
      type="lowest"
      direction="row"
      theme="darker"
    >
      Our
      <nuxt-link
        to="/health-insurance/lowest-price-guarantee/"
        class="underline text-primary inline-block"
      >
        Lowest Price Guarantee
      </nuxt-link>
      means that even if you could find an identical policy for cheaper elsewhere, we’ll give you one months premium free
    </molecules-direct-price>

    <molecules-home-talk-to-us
      v-if="!abTests.originVariant"
      inner-wrapper-class="!items-start gap-[24px] md:gap-[88px] lg:min-w-[954px]"
      class="bg-gray-10 py-[2.5rem] md:py-[5rem] lg:py-[5rem]"
      :buttons="[
        {
          label: 'START LIVE CHAT',
          callback: intercomAction
        }
      ]"
      image-lazy-load
    >
      <template #header>
        <p class="hidden md:block">
          Get Instant <strong class="font-extrabold">Expert Help</strong> With Our <br/>Live Chat
        </p>
        <p class="md:hidden block">
          Get Instant <strong class="font-extrabold">Expert Help</strong> With Our Live Chat
        </p>
      </template>

      <template #sub-header>
        <p>
          <span class="font-extrabold">Our experts have over ten years experience</span> in wringing every last cent of value from your policy. Chat to them now and see how much you could save.
        </p>
      </template>
      <template #buttom-disclaimer>
        <p class="mt-[1rem] text-[0.875rem] italic text-gray-80">
          * Agents available, Mon-Fri 9am-6pm AEST
          <br />
          Typical response time: 5 minutes
        </p>
      </template>

      <template #right-content>
        <atoms-card class="w-full md:max-w-[20.125rem] lg:max-w-[20.125rem] pt-6 pb-8 px-4">
          <div
            v-for="(content, contentIndex) in liveChatContent"
            :key="content"
            class="grid-cols-[auto,1fr] grid"
            :class="{
              'min-h-[84px]': contentIndex != liveChatContent.length - 1
            }"
          >
            <div
              class="before:content-[''] relative border-blue-900 border-2 rounded-[100%] w-[40px] h-[40px] flex items-center justify-center font-extrabold"
              :class="{
                'before:min-h-[40px] before:border-l-2 before:border-dashed before:border-blue-900 before:top-[40px] before:absolute': contentIndex != liveChatContent.length - 1
              }"
            >
              {{  contentIndex + 1 }}
            </div>
            <!-- eslint-disable vue/no-v-html -->
            <div class="font-bold text-[1rem] md:text-[1.125rem] ml-3" v-html="content"></div>
          </div>
        </atoms-card>
      </template>
    </molecules-home-talk-to-us>

    <div v-if="!abTests.originVariant" class="bg-blue-900 py-10 text-white">
      <div class="max-w-wrapper-md mx-auto px-4">
        <p class="text-[1.5rem] md:text-[2rem] text-left md:text-center">Your <span class="font-extrabold">Health Insurance Experts</span> Are Here To Help</p>
        <atoms-button
          theme="primary"
          class="mx-auto my-6 w-full md:w-auto"
          size="large"
          @click="intercomAction"
        >START LIVE CHAT</atoms-button>
      </div>

      <molecules-carousel-agents
        class="mx-auto text-center py-5"
        vertical="Health Insurance"
      />
    </div>

    <molecules-home-company-comparison
      :assets-base-url="`${APP_ASSET_BASEURL}health-insurance/partners/`"
      :companies="PARTNER_ITEMS"
      class="!bg-white"
      theme="v2"
      more-button-url="/health-insurance/companies/"
      maxWidth="max-w-wrapper-lg"
      is-center
      is-carousel
      image-lazy-load
    >
      <template #header>
        Our <strong>private health insurance</strong> partners
      </template>
    </molecules-home-company-comparison>

    <molecules-home-great-deal
      :cards="GREAT_DEAL_ITEMS"
      :col-count="4"
      class="bg-blue-5"
      card-wrapper-max-width="w-wrapper-lg"
      image-lazy-load
    >
      <template #header>
        <strong>Why compare</strong> health insurance?
      </template>

      <template #sub-header>
        Here’s how our experts can <strong>help you with your health insurance</strong>
      </template>
    </molecules-home-great-deal>

    <molecules-home-comparison-blocks
      :items="INFO_TABLE"
      is-carousel
    >
      <template #header>
        What are the <span class="md:font-bold">benefits of <span class="font-bold">private health cover?</span></span>
      </template>
      <template #footer>
        <p>* Subject to income test and appropriate cover being held.</p>
        <p>** Payable when health cover is eventually purchased. LHC loading is added to the hospital cover premium.</p>
      </template>
    </molecules-home-comparison-blocks>

    <MoleculesHomeCoverTiers
      title="What is <strong>hospital cover?</strong>"
      desc="This is the part of your cover that pays for your hospital stay, hospital care, and any medications you receive <br class='hidden lg:block' /> while in hospital. What treatments you’re covered for, and the extent of that cover depends on the tier of cover<br class='hidden lg:block' /> you get. See below:"
      :list="TIERS_LIST"
    />

    <AtomsHomeComparisonTable
      title="How much do <strong>health insurance premiums cost?</strong>"
      footer="Now that you’ve got an idea of what you need, speak to the <strong>experts at Compare Club</strong>. We can match the policies on our panel to your needs to make sure you’re on cover that suits you at a price that won’t break the bank."
      :costs="COMPARISON_TABLE_DATA"
    />

    <molecules-home-table-tabs
      :tabs="[
        {
          label: 'First-Time Buyers',
          key: 'first-time-buyers'
        },
        {
          label: 'Switching Plans',
          key: 'switching-plans'
        }
      ]"
      :list="WHY_GET_HEALTH_INSURANCE"
      :title="{
        'First-Time Buyers': 'Getting <strong>health insurance</strong> for the first time',
        'Switching Plans': 'Switching <strong>health insurance</strong>'
      }"
      :sub-header="{
        'First-Time Buyers': 'Being smart when selecting health cover means you get more than just a tax saving. From free dental check-ups to peace of mind you\'re covered for common hospital procedures, you can balance what you need against your budget.',
        'Switching Plans': 'Reviewing your health cover regularly helps make sure that you\'re getting value for money. It also means you\'ve got the right insurance for what you need tomorrow at the right price. That\'s vital at a time when every cent counts.'
      }"
    />

    <molecules-home-talk-to-us
      class="bg-dark py-10 md:!py-16 px-4"
      theme="dark"
      inner-wrapper-class="!bg-white !text-dark rounded-[1.25rem]"
      :buttons="[
        {
          label: 'GET A QUOTE',
          to: verticalData?.ovhcPage,
          hasIcon: true
        },
        {
          label: 'CALL 1300 757 819',
          phone: '1300 757 819',
          theme: 'secondary'
        }
      ]"
      image-lazy-load
    >
      <template #header>
        Get <strong class="font-extrabold">overseas visitors health cover</strong> <br class="hidden lg:block" />in minutes
      </template>

      <template #sub-header>
        <p class="mb-8">
          Our experts can help make your journey into Australia as easy as possible by helping you get overseas visitors health cover before you’ve arrived.
        </p>

        <p>
          We can even help you receive your certificate of insurance online and our OVHC policies meet visa condition 8501.
        </p>
      </template>

      <template #right-content>
        <NuxtImg
          src="images/overseas-visitors.svg"
          alt="Overseas Visitors"
          width="260"
          height="240"
          class="m-auto pb-6"
        />
      </template>
    </molecules-home-talk-to-us>

    <div class="bg-white px-4 lg:px-0 pt-[2.125rem] pb-20 lg:pt-20">
      <div class="max-w-wrapper-md mx-auto">
        <p class="text-2xl  md:text-4.5xl text-center leading-10 md:leading-[3rem]">
          How
          <strong class="font-extrabold">our experts</strong> have<br />
          <strong class="font-extrabold">helped Australians</strong>
          like you
        </p>

        <MoleculesTrustpilotReviews
          class="mt-8"
          :tags="_VERTICAL && _VERTICAL.replace(/\s/g, '-')"
        />
      </div>
    </div>

    <molecules-home-talk-to-us
      :buttons="[
        {
          label: 'COMPARE HEALTH INSURANCE',
          to: verticalData?.formPage
        }
      ]"
      :card-cta-config="{
        class: 'inline-block mt-4 w-full',
        to: '/health-insurance/rate-rise-calculator/',
        label: 'CALCULATOR'
      }"
      class="bg-gray-10"
      card-title-position="top"
      image-lazy-load
    >
      <template #header>
        Save on your
        <strong class="font-extrabold">health cover</strong>
        in just <br class="hidden lg:block" />
        minutes
        as some premiums are set <br class="hidden lg:block" />
        to
        <strong class="font-extrabold">increase</strong>.
      </template>

      <template #sub-header>
        Over the last five years our
        <strong>experts have saved</strong>
        Australians an
        <strong>average of {{ HOME_TALK_TO_US.averageSave }}</strong>
        when they've compared and switched health cover through us. Save on your health premiums with our
        <strong>experts today. </strong>
        <a
          href="https://compareclub.com.au/health-insurance/2023-premium-increase/"
          class="underline font-bold inline-block"
        >
          Find out more.
        </a>
      </template>

      <template #bottom-content>
        <p class="text-gray-80 text-sm mt-6 italic">
          * Based on <strong>336,746 customers</strong> between <strong>1 Jan 2019 - 23 December 2023.</strong>
        </p>
      </template>

      <template #cta-card-title>
        See how <strong>you can save</strong> while
        many health premiums spike
      </template>

      <template #cta-card-description>
        Use our <strong>premium increase calculator</strong>.
      </template>
    </molecules-home-talk-to-us>

    <AtomsAuthorBlock
      name="Chris Stanley"
      description="Chris Stanley is the sales & operations manager of health insurance at Compare Club. With extensive experience and expertise, Chris is a trusted leader known for his deep understanding of health insurance markets, policies, and coverage options. As the sales & operations manager of health insurance, Chris leads a team of dedicated professionals committed to helping individuals and families make informed decisions about their health insurance needs."
      job-title="Sales & Operations Manager for Health Insurance"
      image="/images/authors/chris-stanley.png"
    />

    <atoms-site-links :items="SITE_LINKS">
      <template #header>
        Learn more about <strong>health insurance</strong>
      </template>
    </atoms-site-links>

    <molecules-faq
      id="glossary"
      title="A glossary of health insurance terms"
      :list="GLOSSSARY_LIST"
      :multiple-collapse="false"
      class="bg-gray-10"
      hide-schema
    >
      Health insurance can be complicated. Below are some of the key terms you may see when comparing health insurance
    </molecules-faq>

    <molecules-faq
      id="faq"
      title="Health Insurance FAQs"
      :list="FAQ_ITEMS"
      :multiple-collapse="false"
    >
      Common questions about comparing health insurance. Here are some of the most frequently asked questions we
      receive about health insurance:
    </molecules-faq>

    <molecules-home-guide-tiles
      bg-class="bg-gray-10"
      :action-button-url="verticalData?.guidesUrl"
      :guides="GUIDES_ITEMS"
      image-lazy-load
      theme="v2"
    >
      <template #header>
        All your <strong class="font-extrabold">health insurance questions answered</strong>
        <br class="hidden lg:block" />
        with our in-depth guides
      </template>
    </molecules-home-guide-tiles>

    <molecules-home-footer-info
      theme="white-boxed"
      container-class="lg:py-20"
    >
      <template #title>
        Things you should know
      </template>

      <template #description>
        <p class="mb-4">
          As our customer you'll be provided with quotes directly from the insurer for the product you intend to purchase. We manage the application and deal with the administration work and insurer. We do not charge you a fee for the service we provide, the insurer simply remunerates us in return for setting up your policy.
        </p>

        <p>
          The financial and insurance products compared on this website do not necessarily compare all features that may be relevant to you. Comparisons are made on the basis of price only and different products may have different features and different levels of coverage. Compare Club does not compare all policies available in Australia and our partner insurers may not make all policies available to Compare Club.
        </p>
      </template>
    </molecules-home-footer-info>
  </div>
</template>

<script setup>
import {
  GREAT_DEAL_ITEMS,
  GUIDES_ITEMS,
  PARTNER_ITEMS,
  FAQ_ITEMS,
  INFO_TABLE,
  COMPARISON_TABLE_DATA,
  WHY_GET_HEALTH_INSURANCE,
  SITE_LINKS,
  GLOSSSARY_LIST,
  TIERS_LIST,
  HOME_TALK_TO_US
} from '~/constants/pages/health-insurance'

const { devices } = deviceChecker()
const $route = useRoute()

defineOptions({
  name: 'HealthInsurance'
})

generateMeta({
  title: 'Compare Health Insurance Australia | Compare Club',
  ogTitle: 'Compare Health Insurance Australia | Compare Club',

  description: 'Australia\'s best rated health insurance comparator. Compare & save in minutes.',
  ogDescription: 'Australia\'s best rated health insurance comparator. Compare & save in minutes.',

  ogUrl: 'https://compareclub.com.au/health-insurance/',
  ogType: 'product'
}, pageIsFunnel($route))

const _VERTICAL = VERTICAL.HEALTH_INSURANCE
const verticalData = VERTICALS[_VERTICAL]

useJsonld(() => {
  return {
    '@context': 'https://schema.org/',
    '@type': 'Product',
    name: 'health insurance comparison',
    image: 'https://compareclub.com.au/_ipx/s_190x30/images/cc-logo-new.svg',
    url: 'https://compareclub.com.au/health-insurance/',
    description: 'Compare health insurance in Australia with. Compare Club. Quickly filter hundreds of policies to find the cover you need at a price you\'ll like.',
    brand: {
      '@type': 'Brand',
      name: 'Compare Club'
    },
    aggregateRating: {
      '@type': 'AggregateRating',
      ratingValue: '4.7',
      bestRating: '5',
      worstRating: '1',
      ratingCount: '3028'
    }
  }
})

const liveChatContent = [
  'Use an expert to work out what you need',
  'Review recommended policies',
  'Purchase your perfect policy from our panel'
]
// TODO: remove abtests once finalized
const abTests = ref({
  intercomVariant: false,
  originVariant: true
})

const intercomAction = () => {
  if (!Intercom) {
    return
  }
  Intercom('showNewMessage')
}
</script>
